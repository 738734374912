@font-face {
  font-family: 'DIN-Condensed';
  src: url('./assets/font/DIN-Condensed-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Influence';
  src: url('./assets/font/D-DIN-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@600&family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:wght@400;500;700&family=Open+Sans:wght@400;700&family=Urbanist:wght@300;400;500;700;900&display=swap');

body {
  font-family: inter, DIN-Condensed, sans-serif, Cairo, Montserrat !important;
  font-variant: normal !important;
  color: white;
  background-color: #202020;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
input::placeholder {
  font-size: 16px !important;
}
input {
  font-size: 16px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
