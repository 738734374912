@font-face {
  font-family: 'DIN-Condensed';
  src: url('./assets/font/DIN-Condensed-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Influence';
  src: url('./assets/font/Influence.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
